import { fetchWithDefaults } from '../config/fetchDefault';
import { useSelector } from 'react-redux';

const fetchPdf= async (url,setAttachedImage,setVariables, setLoading)=>{
    try {
        //setLoading(true);
        const response = await fetchWithDefaults(
            `/parsePdf`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    file_url: url,
                })
            }
        );

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
       // setLoading(false)
        if(jsonData.success===true){
            setAttachedImage(jsonData.filename);
            setVariables(false);
        }
    } catch (error) {
        //setLoading(false)
        console.error('Error fetching data:', error);
    }
}

/**
 * This function will convert br and div tags into linebreaks and will remove all other html tags
 * @param {string} message The message to be sanitized
 * @returns {string}
 */
const sanitizeTextMessage = (message) => {
    return message.replaceAll(/<br\s*\/?>/g, "\n").replaceAll(/<\/?div>/g, "\n").replaceAll(/<[^>]*>/g, '')
}
export { fetchPdf, sanitizeTextMessage };