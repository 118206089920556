
import './App.css';
import './css/dashboard.css';
import './css/queue.css';
import './css/mainBody.css';
import React, { useState, useEffect, useRef } from 'react';
import HeaderBar from './template/headerBar';
import TopBar from './template/topBar';
import LeadList from './template/leads/leads';
import ScheduledCalls from './template/scheduling/calls/scheduleCalls';
import ScheduledAppointment from './template/scheduling/appointment/scheduledAppt';
import WorkflowFollowup from './template/workflowFollowup';
import Teams from './template/team/team';
import SoftPhone from './template/phone/softPhone';
import { Device } from '@twilio/voice-sdk';
import AgentActivity from './template/activity/agentActivity';
import LeadCard from './template/leads/leadCard';
import AddLeads from './template/uploadLeads';
import Numbers from './template/number/number';
import Voicemail from './template/voicemail/voicemail';
import VoiceDrops from './template/voicemail/voiceDrop';
import CallForward from './template/callForwarding/callForward';
import TrustHub from './template/trust/trustHubMain';
import EmailTemplates from './template/templates/emailTemplates';
import Objection from './template/templates/objections';
import TextTemplates from './template/templates/textTemplates';
import Script from './template/templates/scripts';
import LeadStatus from './template/status/leadStatus';
import LeadTags from './template/tags/leadTags';
import WorkflowMain from './template/workflow/workflowMain';
import Integrations from './template/integrations/Integrations.js';
import FollowUp from './template/followUp/followUp';
import Market from './template/market/market';
import LeadType from './template/leadTypes/leadTypes';
import AgentSubscription from './template/subscription/agentSubscription';
import { fetchWithDefaults } from './config/fetchDefault';
import { getMarketInfo } from './utilities/marketUtil';
import { unixTommddyyyy, timestampConvert, unixTommddhia } from './utilities/dashFunction';
import QueueModule from './template/callQueue/queue';
import LeaderBoard from './template/leaderBoard/leaderBoard';
import Conversations from './template/conversations/convos';
import image from './assets/icons/loading-loader.gif';
import EmptyQueue from './template/dashboard/frontPageOptions/emptyQueue';
import QuilityParentBlock from './template/elements/quilityParentBlock';
import Overview from './template/kanban/overview';
import FreshChat from 'react-freshchat';
import FrontPremadeFilterBlock from './template/frontpage/frontPremadeFilterBlock.js';
import { fetchAppInformation, fetchFilterData, fetchActivity, setActiveFilter, saveTextingNumbers, getSubscriptionInfo } from './utilities/appSetup';
import { useSelector, useDispatch } from 'react-redux';
import ConversationPreview from './template/frontpage/convoPreviewBlock.js';
import { setQueue, setBackup, setWholeQueue } from './features/queueSlice';
import { setRefreshLogin } from './features/agentSlice';
import MissedCalls from './template/phone/missedCalls.js';
import { createBrowserHistory } from 'history';
import LoginPopup from './template/loginPopup.js';
import { setCall } from './features/callSlice.js';
import Tutorial from './template/tutorial/tutorial.js';
import InformationPopup from './template/popup/informationPopup.js';
import CallRecording from './template/recording/recording.js';
import Voicemails from './template/phone/voicemails.js';
import InfoAlert from './template/popup/infoAlert.js';
import SubscribeModal from './template/subscription/subscribeModal.js';
import Icon from './assets/icons/icon.js';
import { initIntercom } from './utilities/intercomChat.js';
const history = createBrowserHistory();



function App({ currentQuery, load, auth, agentId, signedIn, signOut, handleSetUnread, unreadMessages, newMessages }) {
    const isFeature457Enabled = process.env.REACT_APP_FUNNEL_FEATURE_457 === 'true'
    const dispatch = useDispatch();
    const agentIdNumber = agentId;
    const popups = useSelector((state) => state.popups.popups);
    const agentInfo = useSelector((state) => state.agent.userData);
    const subscription = useSelector((state) => state.subscription.subscription);

    const call = useSelector((state) => state.call.call);
    const refreshLogin = useSelector((state) => state.agent.refreshLogin);
    const queue = useSelector((state) => state.queue.queue);
    const missedCalls = useSelector((state) => state.missedCall.missedCall);
    const [callStatus, setCallStatus] = useState('closed');
    const [appInfo, setAppInfo] = useState([]);
    const [holdingQueue, setHoldingQueue] = useState(false);
    const [page, setPage] = useState(false);
    const [pathTitle, setPathTitle] = useState();
    const [subscribe, setSubscribe] = useState(false);
    const [callerId, setCallerId] = useState([]);
    const [isMin, setListMin] = useState(false);
    const [barState, setBarState] = useState(false);
    const [leadCard, setLeadCard] = useState([]);
    const [workParams, setWorkParams] = useState();
    const [infoAlert, setInfoAlert] = useState(false);
    const [twilioDevice, setTwilioDevice] = useState(false);
    const [activity, setActivity] = useState(false);
    const [calledQueue, setCalledQueue] = useState([]);
    const [searchQueue, setSearchQueue] = useState(false);
    const [open, setOpen] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [textingNumbers, setTextingNumbers] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newAppt, setNewAppt] = useState(false);
    const [isActivityToggled, setIsActivityToggled] = useState(false);
    const [dialActivity, setDialActivity] = useState(false);
    const [marketData, setMarketData] = useState(false);
    const [batch, setBatch] = useState(false);
    const [renew, setRenew] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);
    const [bulkOpen, setBulkOpen] = useState(false);
    const [refreshQueue, setRefreshQueue] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState(false);
    const [oneLoad, setOneLoad] = useState(false);
    const hasNumberLookupBeenCalledRef = useRef(false);
    const [permission, setPermission] = useState(false);


    const handleActivityToggle = async (init) => {
        let period;
        if (init) {
            period = 'week'
        }
        else {
            setIsActivityToggled(prevState => !prevState);
            if (isActivityToggled) {
                period = 'week';
            }
            else {
                period = 'month';
            }
        }

        try {
            const response = await fetchWithDefaults(`/dialActivity?period=${period}&agent=${agentIdNumber}`);
            if (!response.ok) {
                setRenew(true);
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setDialActivity(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const componentMap = {
        //Tasks: TaskMain,
        'Scheduled Calls': ScheduledCalls,
        'Scheduled Appointments': ScheduledAppointment,
        'Workflow followup': WorkflowFollowup,
        Team: Teams,
        Activity: AgentActivity,
        'Add Leads': AddLeads,
        Number: Numbers,
        Voicemail: Voicemail,
        VoiceDrop: VoiceDrops,
        'Call Forwarding': CallForward,
        'Trust Hub': TrustHub,
        'Text Templates': TextTemplates,
        'Email Templates': EmailTemplates,
        Objections: Objection,
        Scripts: Script,
        'Lead Statuses': LeadStatus,
        Tags: LeadTags,
        Workflows: WorkflowMain,
        'Follow Ups': FollowUp,
        Subscription: AgentSubscription,
        Market: Market,
        'Lead Types': LeadType,
        'Integrations': Integrations,
        'Overview': Overview,
        'Call Recording': CallRecording
    };

    const ComponentToRender = componentMap[pathTitle] || LeadList;
    const clearQueue = async () => {
        try {
            const response = await fetchWithDefaults(
                `/clearQueue`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        agent: agentInfo.agentId,
                    })
                }
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json()
            updateQueue([]);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleSoftPhone = (value) => {
        setCallStatus(value)
    }

    const openQueue = () => {
        setOpen(!open);
        setExpanded(false);
    }
    const handlingClearHolding = () => {
        setHoldingQueue(false);
    }
    const expandSession = () => {
        setExpanded(!expanded);
    }
    const jumpIntoQueue = () => {
        setOpen(!open);
        setExpanded(!expanded);
    }

    const openLead = async (leadId, from) => {
        setListMin(true);
        try {
            setLoading(true);
            //history(`/lead/${leadId}`);
            const response = await fetchWithDefaults(`/lead/leadCard?lead=${leadId}`);
            if (!response.ok) {
                dispatch(setRefreshLogin(true));
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            /*if(from){
                setPermission(true)
            }*/
            setLeadCard(jsonData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const changeBarState = () => {
        setBarState(!barState);
    }
    const numberLookup = async (number) => {
        setCallerId({ name: number, lead_id: null });
        try {
            const response = await fetchWithDefaults(`/numberLookup?agent=${agentIdNumber}&number=${number}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setCallerId(jsonData);

            hasNumberLookupBeenCalledRef.current = false;

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const setUpPhone = async () => {
        try {
            const response = await fetchWithDefaults(`/twilioToken?agent=${agentIdNumber}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            const deviceOptions = {
                tokenRefreshMs: 10000
            }
            saveTextingNumbers(dispatch, jsonData[0].textingNumbers)
            setTextingNumbers(jsonData[0].textingNumbers);
            const device = new Device(jsonData[0].token, deviceOptions);
            device.register();
            device.addListener('registered', device => {
                console.log('The device is ready to receive incoming calls.')
            });

            device.on('incoming', call => {

                if (!hasNumberLookupBeenCalledRef.current) {
                    numberLookup(call.parameters.From);
                    hasNumberLookupBeenCalledRef.current = true; // Set the flag to true after the first call
                }

                setCallStatus(call.status());
                dispatch(setCall(call));
                call.on('cancel', () => {
                    setCallStatus('closed')
                });
            });

            device.on('tokenWillExpire', async () => {
                const token = await getNewToken();
                device.updateToken(token);
                setTwilioDevice(device);

            });
            setTwilioDevice(device);
        } catch (error) {
            console.error('Error fetching data:', error);
            signOut();
        }
    }
    const getNewToken = async () => {
        try {
            const response = await fetchWithDefaults(`/twilioToken?agent=${agentIdNumber}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            return jsonData[0].token;
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    function maxList() {
        setListMin(false);
        setLeadCard([]);
    }
    const fetchPage = async (path) => {
        history.push(`/${path}`, { forced: true });
        const oldPath = path;
        setLoading(true);
        if (path === 'dash') {
            setPage(false);
            setLoading(false);
        }
        else if (path === 'syncLeads') {
            setPage(false);
            try {
                const response = await fetchWithDefaults(`/syncLeads?agent=${agentIdNumber}`);
                if (!response.ok) {
                    dispatch(setRefreshLogin(true));
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                if (jsonData.message === 'refresh') {
                    const baseUrl = `${window.location.protocol}//${window.location.host}`;
                    window.location.href = `https://auth.quility.com/authorize?response_type=code&client_id=kCMuOYXm4BSuAII1sJPoEeRjTgH9Pr1J&redirect_uri=${baseUrl}/api/quility/authorize&scope=profile email read:Agents&audience=http://api.quility.com&state=${agentIdNumber}`;
                }
                setLoading(false);
                setInfoAlert({ title: "Your Lead sync is in process!", info: "This can take up to 10 minutes for all leads to process. Thank you for your patience" })
            } catch (error) {
                setLoading(false);
                console.error('Error fetching data:', error);
            }
        }
        else {
            let pathExtension;
            if (path.substr(0, 5) == 'batch') {

                pathExtension = '?batch=' + path;
                path = 'Viewing_Batch';
            }
            else {
                pathExtension = `?agent=${agentIdNumber}&organization=${agentInfo.organization}`;
                path = path;
            }
            try {
                const response = await fetchWithDefaults('/' + path + pathExtension);
                if (!response.ok) {
                    dispatch(setRefreshLogin(true));
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                if (jsonData.status == false) {
                    setPage([{ type: false }]);
                }
                else {
                    if (oldPath.substr(0, 5) == 'batch') {
                        setPage(jsonData);
                        setBatch(oldPath);

                    }
                    else {
                        setPage(jsonData);
                    }
                }
                setPathTitle(path.replace("_", " "));
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error('Error fetching data:', error);
            }

        }
    };

    const addToQueue = async (search, cat) => {
        setLoading(true);
        try {
            const requestOptions = {
                method: 'PATCH',
                body: JSON.stringify({
                    agent: agentIdNumber,
                    leadQuery: search,
                    cat: cat
                })
            };

            const response = await fetchWithDefaults('/addToQueue', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            const updatedJsonData = jsonData.map((item, index) => ({
                ...item,
                queuePos: index
            }));
            updateQueue(updatedJsonData);
            setLoading(false);
            setHoldingQueue(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const replaceQueue = async (search) => {
        setLoading(true);

        try {
            const requestOptions = {
                method: 'PATCH',
                body: JSON.stringify({
                    agent: agentIdNumber,
                    leadQuery: search,
                })
            };
            const response = await fetchWithDefaults('/replaceQueue', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            const updatedJsonData = jsonData.map((item, index) => ({
                ...item,
                queuePos: index
            }));
            updateQueue(updatedJsonData);
            setLoading(false);
            setHoldingQueue(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const addSelectedToQueue = async (leads) => {
        setLoading(true);

        try {
            const requestOptions = {
                method: 'PATCH',
                body: JSON.stringify({
                    agent: agentIdNumber,
                    leads: leads,
                })
            };

            const response = await fetchWithDefaults('/leadsToQueue', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            const updatedJsonData = jsonData.map((item, index) => ({
                ...item,
                queuePos: index
            }));
            updateQueue(updatedJsonData);
            setLoading(false);
            setHoldingQueue(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const replaceSelectedToQueue = async (leads) => {
        setLoading(true);
        try {
            const requestOptions = {
                method: 'PATCH',
                body: JSON.stringify({
                    agent: agentIdNumber,
                    leads: leads,
                })
            };

            const response = await fetchWithDefaults('/replaceLeadsToQueue', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            const updatedJsonData = jsonData.map((item, index) => ({
                ...item,
                queuePos: index
            }));
            updateQueue(updatedJsonData);
            setRefreshQueue(updatedJsonData[0])
            setLoading(false);
            setHoldingQueue(false);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
        }
    }

    const fetchQueue = async () => {
        try {
            setActiveFilter('Last 90 Days', dispatch);
            const response = await fetchWithDefaults('/queue/callQueue?agent=' + agentIdNumber);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            if (jsonData.length > 0) {
                const updatedJsonData = jsonData.map((item, index) => ({
                    ...item,
                    queuePos: index
                }));
                const categorizeLeads = (leads, dispatch) => {
                    const dead = [];
                    const client = [];
                    const others = [];

                    leads.forEach((lead) => {
                        if (lead.parent_status === 'dead') {
                            dead.push(lead);
                        } else if (lead.parent_status === 'client') {
                            client.push(lead);
                        } else {
                            others.push(lead);
                        }
                    });

                    //dispatch(setDead(dead));
                    //dispatch(setClients(client));
                    dispatch(setQueue(others));
                    dispatch(setBackup(others));
                    dispatch(setWholeQueue(leads))
                };
                categorizeLeads(updatedJsonData, dispatch)

            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {

        const runFunctions = async () => {
            try {

                setLoading(true);
                //await handleActivityToggle(true); // this sets the activity toggle to one week
                await setUpPhone(); // this sets up the phone, gathers twilio token as well as the textable phone numbers
                await getSubscriptionInfo(agentIdNumber, setSubscribe, dispatch);
                await fetchActivity(agentIdNumber, setActivity); // This gathers agents activity
                await fetchFilterData(dispatch, agentIdNumber); // this gathers the filters
                await fetchAppInformation(setAppInfo, dispatch, agentIdNumber, agentInfo.organization); // thisgathers the entire applications data - so things like statuses,templates, numbers, agent info ect.
                await fetchQueue(); //this gathers the queue
                //await getConversations(handleSetUnread, agentIdNumber, dispatch); // this gathers the texting convos
                await getMarketInfo(fetchWithDefaults, agentIdNumber, setMarketData); // gets the market data.
            } catch (error) {
                console.error('Error running functions:', error);
            } finally {
                setLoading(false);
            }
        }
        //if the application information exists
        if (oneLoad) {
            if (signedIn) {
                runFunctions();
                if (auth === 'gather') {
                    syncLeads();
                }
                else if (auth === 'authenticate') {
                    fetchPage("Integrations");
                }
            }
        }
        else {
            setOneLoad(true)
        }
    }, [renew, signedIn, oneLoad])
    const syncLeads = async () => {
        try {
            setLoading(true);
            const response = await fetchWithDefaults(`/syncLeads?agent=${agentIdNumber}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            if (jsonData.message === 'refresh') {
                const baseUrl = `${window.location.protocol}//${window.location.host}`;
                if (baseUrl)
                    window.location.href = `https://auth.quility.com/authorize?response_type=code&client_id=kCMuOYXm4BSuAII1sJPoEeRjTgH9Pr1J&redirect_uri=${baseUrl}/api/quility/authorize&scope=profile email read:Agents&audience=http://api.quility.com&state=${agentIdNumber}`;
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
        }
    }

    const handleUpdateQueueLead = (field, value, leadId) => {
        const updatedData = queue.map(item => {
            if (item.lead_id === leadId) {
                // Create a new object with the updated field
                return {
                    ...item, // Copy all existing properties of the item
                    [field]: value // Update the specified field
                };
            }
            return item;
        });
        dispatch(setQueue(updatedData));
        //return updatedData;
    }


    // this function will gather the results of the filter, and then it will break down the params of the filter, sort it into two categories
    // one category will be used to display the filters used in the filter box (this gets sent to 'filtering options') while the other will then populate
    //the filtering options actual search groups which are then used to update or amend the search.
    const getMyFilter = async (params, jump, name, filter) => {
        if (params === 'list') {
            try {
                setLoading(true);

                const response = await fetchWithDefaults(`/queue/taskLists?agent=${agentId}&type=${filter.search}&timezone=${agentInfo.timezone}`);
                if (!response.ok) {
                    dispatch(setRefreshLogin(true));
                    throw new Error("Network response was not ok");

                }
                setActiveFilter(name, dispatch);
                const jsonData = await response.json();
                if (jsonData.status === false) {
                    dispatch(setQueue([]));
                    dispatch(setWholeQueue([]))
                    setHoldingQueue(false);
                    //setFilteredOptions({searchGroups:[]});
                    setRefreshQueue(false);
                    setLoading(false);
                }
                else {
                    const states = [];
                    const leadTypes = [];
                    const contacts = [];
                    const types = [];
                    const parent = [];
                    const updatedJsonData = jsonData.map((item, index) => ({
                        ...item,
                        queuePos: index
                    }));

                    setLoading(false);
                    updatedJsonData.forEach(item => {
                        const stateObject = { states: item.state, search: 'state' };
                        const typeObject = { lead_type: item.lead_type, search: 'lead_type' };
                        const contactObject = { amount_contacted: item.amount_contacted, search: 'amount_contacted' };
                        const parentStatus = { name: item.parent_status.charAt(0).toUpperCase() + item.parent_status.slice(1), value: item.parent_status, search: 'parent_status' };

                        if (!types.some(obj => obj.states === item.state)) {
                            types.push(stateObject);
                            states.push(stateObject.states);
                        }

                        if (!types.some(obj => obj.lead_type === item.lead_type)) {
                            types.push(typeObject);
                            leadTypes.push(typeObject.lead_type);
                        }
                        if (!types.some(obj => obj.value === item.parent_status)) {
                            types.push(parentStatus);
                            parent.push(parentStatus.value);
                        }
                    });

                    setFilteredOptions(
                        {
                            searchParams: types,
                            searchGroups: [
                                { state: states },
                                { parent_status: parent },
                                { lead_type: leadTypes }
                            ]
                        }
                    );
                    dispatch(setQueue(updatedJsonData));
                    dispatch(setWholeQueue(updatedJsonData))
                    setRefreshQueue(updatedJsonData[0]);
                    if (jump) {
                        jumpIntoQueue();
                    }
                }
            } catch (error) {
                setLoading(false);
                console.error("Error fetching data:", error);
            }
        }
        else if (params === 'date') {
            try {
                setLoading(true);

                const response = await fetchWithDefaults(`/queue/myFilterDate?agent=${agentId}&date1=${filter.first}&date2=${filter.last}`);
                if (!response.ok) {
                    dispatch(setRefreshLogin(true));
                    throw new Error("Network response was not ok");
                }
                setActiveFilter(name, dispatch);
                const jsonData = await response.json();
                if (jsonData.status === false) {
                    dispatch(setQueue([]));
                    setHoldingQueue(false);
                    //setFilteredOptions({searchGroups:[]});
                    setRefreshQueue(false);
                    setLoading(false);
                }
                else {
                    const states = [];
                    const leadTypes = [];
                    const contacts = [];
                    const types = [];
                    const parent = [];
                    const updatedJsonData = jsonData.map((item, index) => ({
                        ...item,
                        queuePos: index
                    }));
                    // dispatch(setQueue(updatedJsonData));
                    setLoading(false);
                    updatedJsonData.forEach(item => {
                        const stateObject = { states: item.state, search: 'state' };
                        const typeObject = { lead_type: item.lead_type, search: 'lead_type' };
                        const contactObject = { amount_contacted: item.amount_contacted, search: 'amount_contacted' };
                        const parentStatus = { name: item.parent_status.charAt(0).toUpperCase() + item.parent_status.slice(1), value: item.parent_status, search: 'parent_status' };

                        if (!types.some(obj => obj.states === item.state)) {
                            types.push(stateObject);
                            states.push(stateObject.states);
                        }

                        if (!types.some(obj => obj.lead_type === item.lead_type)) {
                            types.push(typeObject);
                            leadTypes.push(typeObject.lead_type);
                        }
                        if (!types.some(obj => obj.value === item.parent_status)) {
                            types.push(parentStatus);
                            parent.push(parentStatus.value);
                        }
                    });

                    setFilteredOptions(
                        {
                            searchParams: types,
                            searchGroups: [
                                { state: states },
                                { parent_status: parent },
                                { lead_type: leadTypes }
                            ]
                        }
                    );
                    //dispatch(addToBackup(updatedJsonData));
                    dispatch(setQueue(updatedJsonData));
                    dispatch(setWholeQueue(updatedJsonData))
                    setRefreshQueue(updatedJsonData[0]);
                    if (jump) {
                        jumpIntoQueue();
                    }
                }
            } catch (error) {
                setLoading(false);
                console.error("Error fetching data:", error);
            }
        }
        else {
            try {
                setLoading(true);

                const response = await fetchWithDefaults(`/filters/myFilter?agent=${agentId}&params=${btoa(params)}`);
                if (!response.ok) {
                    dispatch(setRefreshLogin(true));
                    throw new Error("Network response was not ok");
                }
                setActiveFilter(name, dispatch);
                const jsonData = await response.json();
                if (jsonData.status === false) {
                    setLoading(false);
                    dispatch(setQueue([]));
                    dispatch(setWholeQueue([]))
                    //setFilteredOptions([]);
                    setFilteredOptions(null);
                }
                else {
                    const states = [];
                    const leadTypes = [];
                    const contacts = [];
                    const types = [];
                    const parent = [];
                    const updatedJsonData = jsonData.map((item, index) => ({
                        ...item,
                        queuePos: index
                    }));
                    //dispatch(setQueue(updatedJsonData));
                    setLoading(false);
                    updatedJsonData.forEach(item => {
                        const stateObject = { states: item.state, search: 'state' };
                        const typeObject = { lead_type: item.lead_type, search: 'lead_type' };
                        const contactObject = { amount_contacted: item.amount_contacted, search: 'amount_contacted' };
                        const parentStatus = { name: item.parent_status.charAt(0).toUpperCase() + item.parent_status.slice(1), value: item.parent_status, search: 'parent_status' };


                        if (!types.some(obj => obj.states === item.state)) {
                            types.push(stateObject);
                            states.push(stateObject.states);
                        }

                        if (!types.some(obj => obj.lead_type === item.lead_type)) {
                            types.push(typeObject);
                            leadTypes.push(typeObject.lead_type);
                        }
                        if (!types.some(obj => obj.value === item.parent_status)) {
                            types.push(parentStatus);
                            parent.push(parentStatus.value);
                        }
                    });

                    setFilteredOptions(
                        {
                            searchParams: types,
                            searchGroups: [
                                { state: states },
                                { parent_status: parent },
                                { lead_type: leadTypes }
                            ]
                        }
                    );

                    setRefreshQueue(updatedJsonData[0]);
                    dispatch(setQueue(updatedJsonData));
                    dispatch(setWholeQueue(updatedJsonData))

                    if (jump) {
                        jumpIntoQueue();
                    }
                }

            } catch (error) {
                setLoading(false);
                console.error("Error fetching data:", error);
            }
        }
    };

    const updateQueue = (queue) => {
        dispatch(setQueue(queue));
        setRefreshQueue(queue[0])
    }

    useEffect(() => {
        if (load) {
            if (load === 'lead') {
                openLead(currentQuery.split("id=")[1])
            }
            else {
                fetchPage(load);
            }
        }
    }, [load])

    const [sessionStarted, setSessionStarted] = useState(false);

    let intercomInit = false
    useEffect(() => {
        if(isFeature457Enabled===true && !intercomInit && agentInfo.agentId){
            initIntercom(agentInfo.agentId)
            intercomInit = true
        }

    }, [agentInfo.agentId])
    return (
        <>
            <div className="App">

                {!agentInfo.agentCode && agentInfo.organization === 'master' && (
                    <InformationPopup agentCode={true} />
                )}
                {queue.length > 0 && appInfo.length > 0 && (
                    <QueueModule maxList={maxList} setCallerId={setCallerId} sessionStarted={sessionStarted} setSessionStarted={setSessionStarted} setLeadCard={setLeadCard} setRefreshQueue={setRefreshQueue} refreshQueue={refreshQueue} newAppt={newAppt} setNewAppt={setNewAppt} calledQueue={calledQueue} setCalledQueue={setCalledQueue} expandSession={expandSession} jumpIntoQueue={jumpIntoQueue} replaceQueue={replaceQueue} clearQueue={clearQueue} handlingClearHolding={handlingClearHolding} setHoldingQueue={setHoldingQueue} holdingQueue={holdingQueue} openLead={openLead} handleUpdateQueueLead={handleUpdateQueueLead} searchQueue={searchQueue} textingNumbers={textingNumbers} openQueue={openQueue} expanded={expanded} open={open} myQueue={queue} setMyQueue={updateQueue} addToQueue={addToQueue} setWorkParams={setWorkParams} workParams={workParams} device={twilioDevice} formatDate={timestampConvert} />
                )}
                {subscription.length > 0 && subscription[0].activeSubscription === false && !agentInfo.beta && (
                    <SubscribeModal subscription={subscription} />
                )}
                <LeaderBoard />
                <Conversations setLeadCard={setLeadCard} setNewAppt={setNewAppt} newAppt={newAppt} setLoading={setLoading} setCallerId={setCallerId} setCall={setCall} device={twilioDevice} handleSoftPhone={handleSoftPhone} newMessages={newMessages} unreadMessages={unreadMessages} handleSetUnread={handleSetUnread} />
                <MissedCalls openLead={openLead} />
                <Voicemails openLead={openLead} />
                <HeaderBar unreadMessages={unreadMessages} fetchPage={fetchPage} signOut={signOut} barState={barState} openLead={openLead} changeBarState={changeBarState} />
                <TopBar barState={barState} fetchPage={fetchPage} />
                {!page ? (
                    activity && (
                        <div className="outerWrapper">
                            <div className="twoBlocks" style={{ marginTop: '5px' }}>
                                <div className="frontColumn sideColumn">
                                    <FrontPremadeFilterBlock setRefreshQueue={setRefreshQueue} getMyFilter={getMyFilter} title="My Lists" setMyQueue={updateQueue} setLoading={setLoading} jumpIntoQueue={jumpIntoQueue} />
                                </div>
                                <div className="frontColumn centerColumn">
                                    <QuilityParentBlock setLeadCard={setLeadCard} setRefreshQueue={setRefreshQueue} filteredOptions={filteredOptions} textingNumbers={textingNumbers} formatDate={timestampConvert} addToQueue={addToQueue} addSelectedToQueue={addSelectedToQueue} replaceSelectedToQueue={replaceSelectedToQueue} filterOpen={filterOpen} bulkOpen={bulkOpen} jumpIntoQueue={jumpIntoQueue} clearQueue={clearQueue} replaceQueue={replaceQueue} handlingClearHolding={handlingClearHolding} setMyQueue={updateQueue} title='My Leads' nested={[]} data={[]} setHoldingQueue={setHoldingQueue} holdingQueue={holdingQueue} myQueue={queue} openLead={openLead} Additional={EmptyQueue} />
                                </div>
                                <div className="frontColumn sideColumn">
                                    <QuilityParentBlock openLead={openLead} setRefreshQueue={setRefreshQueue} size="small" textingNumbers={textingNumbers} unixTommddyyyy={unixTommddyyyy} handleToggle={handleActivityToggle} isToggled={isActivityToggled} activity={activity} nested={['Appointments', 'Scheduled Calls', 'Marketplace']} data={[activity[0].scheduled, activity[0].scheduledCalls, dialActivity, marketData]} title='Your Business At a Glance' />
                                    <ConversationPreview title="Recent Conversations" />
                                </div>
                            </div>
                        </div>
                    )
                ) : (page.length > 0 ?
                    <ComponentToRender
                        formatDate={pathTitle === 'Follow Ups' ? unixTommddhia : unixTommddyyyy}
                        title={pathTitle}
                        data={page}
                        fetchPage={fetchPage}
                        openLead={openLead}
                        isMin={isMin}
                        leadCard={leadCard}
                        maxList={maxList}
                        addToQueue={addToQueue}
                        batch={batch}
                    />
                    : null)}
            </div>
            {
                call && callStatus === 'closed' ? (
                    null
                ) : (
                    <SoftPhone call={call} callerId={callerId} callStatus={callStatus} openLead={openLead} setCallStatus={setCallStatus} />
                )
            }
            {leadCard.length > 0 &&
                <div className="leadCardWrapper">
                    <div className="quilityCloseWindow" onClick={() => maxList()}><span className="fontello" style={{ marginTop: '-7px' }}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
                    <LeadCard permission={permission} textingNumbers={textingNumbers} newAppt={newAppt} setNewAppt={setNewAppt} data={leadCard} maxList={maxList} setCallerId={setCallerId} setCall={setCall} device={twilioDevice} handleSoftPhone={handleSoftPhone} />
                </div>
            }
            {loading && (
                <div className="loadingScreen">
                    <div className="loaderWrapper">
                        <img src={image} alt="Loading Gif" style={{ width: '100%' }} />
                    </div>
                </div>
            )}
            {refreshLogin && (
                <LoginPopup />
            )}
            {popups.length > 0 && (
                <InformationPopup popups={popups} />
            )}
            {infoAlert && (
                <InfoAlert information={infoAlert} setInfoAlert={setInfoAlert} />
            )}

            {agentInfo.tutorial === 0 && (
                (agentInfo.beta || agentInfo.status) && (
                    <Tutorial />
                )
            )}
            {agentInfo !== '' && isFeature457Enabled===false && (
                <FreshChat
                    token={'ae4a4d16-5a8d-4ab9-90eb-2222b2fed636'}
                    onInit={widget => {
                        widget.user.setProperties({
                            externalId: agentInfo.agentId,
                            email: agentInfo.email,
                            firstName: agentInfo.firstName,
                            lastName: agentInfo.lastName,
                        })
                    }}
                />
            )}
        </>
    );
}

export default App;
