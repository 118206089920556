import React, { useState, useRef, useEffect } from 'react';
import { sanitizeTextMessage } from '../../../utilities/messagingFunctions';

function TextInputBar({ from, inputValue, handleChange, handleHeight, handleCursorPosition, canEditContent}) {
    const isFeature875Enabled = process.env.REACT_APP_FUNNEL_FEATURE_875 === 'true'
    const inputRef = useRef(null);

    // Function to handle input change
    const handleInputChange = () => {
        //const input = inputRef.current.textContent;
        const inputElement = inputRef.current;
        // Get the innerHTML to preserve line breaks
        const rawText = inputElement.innerText
        // Replace HTML line breaks with `\n`

        const processedInput = sanitizeTextMessage(rawText)
        //setInputValue2(processedInput);

        handleChange(processedInput);
    };
    useEffect(() => {
        const updateHeight = () => {
            const height = inputRef.current.clientHeight;
            if(from==='bigConvo'){
                handleHeight((height + 50))
            }
            else{
                handleHeight(height)
            }
        };
        const updateCursorPosition = () => {
            const selection = window.getSelection();
            if (selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                const preSelectionRange = range.cloneRange();
                preSelectionRange.selectNodeContents(inputRef.current);
                preSelectionRange.setEnd(range.startContainer, range.startOffset);
                handleCursorPosition(preSelectionRange.toString().length);
            }
        };
        updateHeight(); // Update height when component mounts
        //inputRef.current.addEventListener('input', updateHeight); // Update height when content changes
        inputRef.current.addEventListener('input', updateCursorPosition);
        return () => {
            if (inputRef.current) {
                inputRef.current.removeEventListener('input', updateHeight); // Cleanup
                inputRef.current.removeEventListener('input', updateCursorPosition);
            }
        };
    }, []);
    const handlePaste = (e) => {
        e.preventDefault(); // Prevent the default paste behavior
        // Extract plain text from the clipboard
        const plainText = e.clipboardData.getData('text/plain');
        // Insert the plain text into the div
        document.execCommand('insertText', false, plainText);
    };
    return (
        <div
            className={isFeature875Enabled===true ? (canEditContent===true ? "demo" : "demoInactive") : 'demo'} // Changed 'class' to 'className'
            contentEditable={isFeature875Enabled===true ? canEditContent ?? false : 'true'}
            ref={inputRef}
            dangerouslySetInnerHTML={{ __html: inputValue }}
            suppressContentEditableWarning={true}
            onBlur={handleInputChange} // Added onBlur event to trigger input change
            onPaste={handlePaste}
        >

        </div>
    );
}

export default TextInputBar;
