import { fetchWithDefaults } from '../config/fetchDefault';
const unixTommddyyyy = (timestamp) => {

    const date = new Date(timestamp * 1000); // Multiply by 1000 to convert seconds to milliseconds
    const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    return formattedDate;

};
const formatTimeStampToLocal=(utcDate)=>{
    const [datePart, timePart] = utcDate.split(' ');
    const [year, month, day] = datePart.split('-').map(Number);
    const [hours, minutes] = timePart.split(':').map(Number);

    // Create a Date object in local time
    const date = new Date(Date.UTC(year, month - 1, day, hours, minutes));

    // Get the local components
    const localMonth = (date.getMonth() + 1).toString().padStart(2, '0');
    const localDay = date.getDate().toString().padStart(2, '0');
    let localHours = date.getHours();
    const localMinutes = date.getMinutes().toString().padStart(2, '0');

    // Determine AM/PM and adjust hours
    const ampm = localHours >= 12 ? 'pm' : 'am';
    localHours = localHours % 12 || 12;

    // Format the local time as MM/DD - h:mma
    const formattedDate = `${localMonth}/${localDay} - ${localHours}:${localMinutes}${ampm}`;

    return formattedDate;

}
const timestampConvert = (inputDate) => {
    if (inputDate === null) {
        return 'Never Contacted';
    }
    const date = new Date(inputDate);

    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = ((date.getHours() % 12) || 12).toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'pm' : 'am';

    const formattedDate = `${month}/${day} @ ${hours}:${minutes}${ampm}`;

    return formattedDate;
};

const formatPhoneNumber = (phoneNumber) => {
    //const cleaned = phoneNumber.replace(/^\+1/, '');
    const cleaned = String(phoneNumber).replace(/^\+1/, '');
    // Remove non-numeric characters
    const numericOnly = cleaned.replace(/\D/g, '');

    // Check if the numeric part has at least 10 digits
    if (numericOnly.length >= 10) {

      return `(${numericOnly.substring(0, 3)}) ${numericOnly.substring(3, 6)}-${numericOnly.substring(6, 10)}`;
    }

    // If the numeric part doesn't have at least 10 digits, return the original input
    return phoneNumber;
};
const unixTommddhia = (unixTimestamp, timezone) => {
    const isFeature983Enabled = process.env.REACT_APP_FUNNEL_FEATURE_983 === 'true'
    const date = new Date(unixTimestamp * 1000);

    if(isFeature983Enabled===true){
        let currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        if(timezone !== null && timezone !== undefined && timezone !== ""){
            currentTimezone = timezone
        }

        const formattedDate = date.toLocaleString('en-US', {
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            timeZone: currentTimezone
        });

        return formattedDate;
    }

    const hours = date.getHours() % 12 || 12; // Convert to 12-hour format
    const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${
    date.getDate().toString().padStart(2, '0')
    } ${hours.toString().padStart(2, '0')}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')} ${date.getHours() >= 12 ? 'pm' : 'am'}`;

    return formattedDate;
};
const getConvo = async (lead, setConvos, setActiveConvo) =>{
    try {
        const response = await fetchWithDefaults(`/getConversationForLead?lead=${lead}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        if(jsonData.status===true){
            //setConversations([newItem, ...conversation])
        }
        if(jsonData.length > 0){
            setConvos(jsonData);
            setActiveConvo(Object.keys(jsonData[0])[0]);
        }

    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export { unixTommddyyyy,timestampConvert,formatPhoneNumber,unixTommddhia, getConvo, formatTimeStampToLocal};